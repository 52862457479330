<template>
  <div class="guangye-goods" v-if="activeIndex">
    <div class="back-arrow" @click="handlerGoBack">
      <img src="@/assets/guangye/back-arrow.png" />
    </div>
    <div class="content" ref="content">
      <div ref="topdom" style="height:0"></div>
      <img class="content-img" v-for="(item,i) in imgList" :key="i"
        :src="getImg(item+'.png')" />
    </div>
    
    <div class="role-con">
      <template v-if="type=='cake'">
        <div class="role" v-for="i in 5" :key="i" :class="{ active: activeIndex==i }" 
          @click="handlerChangeRole(i)">
          <img v-if="activeIndex==i" class="img-avatar" :src="getImg('ava-q-'+i+'-active.png')" />
          <img v-else class="img-avatar" :src="getImg('ava-q-'+i+'.png')" />
          <!-- <span>{{ rolesList[i-1] }}</span> -->
        </div>
      </template>
      <template v-if="type=='banshu'">
        <div class="role" v-for="i in 5" :key="i" :class="{ active: activeIndex==i }"
          @click="handlerChangeRole(i)">
          <img v-if="activeIndex==i" class="img-avatar" :src="getImg('ava-'+i+'-active.png')" />
          <img v-else class="img-avatar" :src="getImg('ava-'+i+'.png')" />
          <!-- <span>{{ rolesList[i-1] }}</span> -->
        </div>
      </template>
      <div class="top-btn" :class="{ show: showToTop }" @click="handlerToTop">
        <img src="@/assets/guangye/top.png" />
      </div>
    </div>
    <div class="buy-btn-con">
      <template v-if="type=='cake'">
        <div class="btn-buy" :class="{ active: clickAction=='shop' }" @click="handlerClickBtn('shop')">
          <img class="img" src="@/assets/guangye/buy-cake.png" />
          <img class="img-active" src="@/assets/guangye/buy-cake-active.png" />
        </div>
      </template>
      <template v-if="type=='banshu'">
        <div class="btn-buy" :class="{ active: clickAction=='shop' }" @click="handlerClickBtn('shop')">
          <img class="img" src="@/assets/guangye/buy-shop.png" />
          <img class="img-active" src="@/assets/guangye/buy-shop-active.png" />
        </div>
        <div class="btn-buy" :class="{ active: clickAction=='mall' }" @click="handlerClickBtn('mall')">
          <img class="img" src="@/assets/guangye/buy-mall.png" />
          <img class="img-active" src="@/assets/guangye/buy-mall-active.png" />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import wx from 'weixin-js-sdk'
// import ajax from '@/utils/ajax.js'
// import config from '@/utils/config'
import CONFIG from '@/utils/config'
import { goodsHaxe } from '../cfg'

export default {
  components: {
  },
  name: 'detail',
  props: {
    type: {
      type: String,
      default: "" // banshu cake
    },
    index: {
      type: [Number,String],
      default: ""
    },
    isDetail: {
      type: Boolean,
      default: false
    },
    goodsData: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      goodsHaxe,

      activeIndex: 0,

      imgDataHaxe: {
        "banshu-1": [
          "b-1-1",
          "b-1-24",
          "b-1-37",
        ],
        "banshu-2": [
          "b-2-1",
          "b-2-24",
          "b-2-36",
        ],
        "banshu-3": [
          "b-3-1",
          "b-3-24",
          "b-3-36",
        ],
        "banshu-4": [
          "b-4-1",
          "b-4-24",
          "b-4-36",
        ],
        "banshu-5": [
          "b-5-1",
          "b-5-24",
          "b-5-36",
        ],
        "cake-1": [
          "c-1-13",
          "c-1-24",
          "c-1-36",
        ],
        "cake-2": [
          "c-2-13",
          "c-2-24",
          "c-2-36",
        ],
        "cake-3": [
          "c-3-13",
          "c-3-24",
          "c-3-36",
        ],
        "cake-4": [
          "c-4-13",
          "c-4-24",
          "c-4-36",
        ],
        "cake-5": [
          "c-5-13",
          "c-5-24",
          "c-5-36",
        ],
        "cake-zaoniao-1": [
          "c-1-zaoniao",
          "c-1-2",
          "c-1-3",
        ],
        "cake-zaoniao-2": [
          "c-2-zaoniao",
          "c-2-2",
          "c-2-3",
        ],
        "cake-zaoniao-3": [
          "c-3-zaoniao",
          "c-3-2",
          "c-3-3",
        ],
        "cake-zaoniao-4": [
          "c-4-zaoniao",
          "c-4-2",
          "c-4-3",
        ],
        "cake-zaoniao-5": [
          "c-5-zaoniao",
          "c-5-2",
          "c-5-3",
        ],
      },

      rolesList: [
        "萧逸",
        "陆沉",
        "齐司礼",
        "查理苏",
        "夏鸣星",
      ],

      imgList: [],

      showToTop: false,

      clickAction: "",
    }
  },
  watch: {
    type: function(val, oldval) {
      console.log("watch type", oldval, val)
      if(val != oldval){
        // this.activeIndex = 1
        this.handlerLoadData(1)
      }
    }
  },
  mounted(){
    console.log("mounted", this.type)
    this.clickAction = ""
    
    if(this.type){
      // this.activeIndex = 1
      this.handlerLoadData(1)
    }
  },

  methods: {
    handlerLoadData(index, isEarly){
      this.clickAction = ""
      const key = this.type + "-" + (isEarly ? "zaoniao-" : "") + index
      this.activeIndex = index
      this.imgList = this.imgDataHaxe[key]
      // this.$refs["content"].scrollTo({
      //   top: 0,
      //   // behavior: "smooth",
      // })
      
      this.$nextTick(()=>{
        const dom = this.$refs["content"]
        dom && dom.addEventListener('scroll', this.onScroll)
      })
    },

    onScroll(){
      const topdom = this.$refs["topdom"]
      if(topdom){
        this.showToTop = topdom.getBoundingClientRect().top < -20
      }
    },

    handlerClickBtn(action){
      let goods = null
      if(this.type == "cake"){
        goods = this.goodsHaxe['cake-'+this.activeIndex]
      }else{
        if(action == "shop"){
          goods = this.goodsHaxe['banshu-'+this.activeIndex]
        }else{
          goods = this.goodsHaxe['m-banshu-'+this.activeIndex]
        }
      }
      if(!goods) return

      console.log("goods", goods)

      this.clickAction = action
      setTimeout(()=>{
        this.clickAction = ""
        if(action == "shop"){
          wx.miniProgram.switchTab({
            url: "/pages/shop/shop"
          })
          wx.miniProgram.postMessage({
            data: {
              ...goods,
              from: 'holiland',
              page: 'shop',
              
              // type_id: 46, 
              // son_type_id: "", 
              // goods_id: 37
            }
          })
        }else{
          wx.miniProgram.navigateTo({
            url: '/subPackages/mall/goods-detail/goods-detail?id=' + goods.goods_id,
          })
        } 
      }, 300)
    },

    handlerChangeRole(index){
      this.handlerLoadData(index)
    },

    handlerGoBack(){
      this.$emit('back')
    },

    handlerToTop(){
      this.$refs["content"].scrollTo({
        top: 0,
        behavior: "smooth",
      })
    },

    getImg(path){
      return CONFIG.P1_IMG_DOMAIN + "act/guangye/img/" + path
    },
  }
}
</script>

<style lang="scss">
.guangye-goods {
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.back-arrow{
  width: rem(56);
  height: rem(57);
  position: fixed;
  left: rem(15);
  top: rem(25);
  img{
    width: 100%;
    height: 100%;
  }
}

.bg-t{
  margin-top: rem(16);
  width: rem(347);
  height: rem(66);
  display: flex;
  justify-content: space-between;
  align-items: center;
  img{
    width: rem(65);
    height: rem(66);
  }
}
.img-dialog{
  width: rem(305);
  height: rem(101);
  margin-top: rem(-35);
}
.img-type{
  width: rem(190);
  height: rem(46);
  margin-top: rem(10);
}
.img-border.cake{
  width: rem(274);
  height: rem(365);
  margin-top: rem(10);
}
.img-border.banshu{
  width: rem(276);
  height: rem(365);
  margin-top: rem(10);
}
.img-name{
  //width: rem(276);
  height: rem(16);
  margin-top: rem(21);
}
.img-banner{
  width: rem(310);
  height: rem(190);
  margin-top: rem(21);
}
.img-txt{
  //width: rem(310);
  //height: rem(190);
  margin-top: rem(15);
}
.img-txt1{
  height: rem(182);
}
.img-txt2{
  height: rem(119);
}
.img-txt3{
  height: rem(160);
}
.img-txt-banshu1{
  height: rem(234);
}

.content-con{
  flex: 1;
  width: 100%;
  overflow-y: auto;
}
.content{
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  font-size: 0;
  .content-img{
    width: 100%;
  }
}

.buy-btn-con{
  margin-bottom: rem(48);
  display: flex;
  justify-content: center;
  align-items: center;
  .btn-buy{
    width: rem(146);
    height: rem(38);
    img{
      width: 100%;
      height: 100%;
    }
    .img-active{
      display: none;
    }
    &.active,
    &:active{
      .img{
        display: none;
      }
       .img-active{
        display: block;
      }
    }
    +.btn-buy{
      margin-left: rem(13);
    }
  }
}

.role-con{
  position: relative;
  width: rem(343);
  // height: rem(87);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: rem(19) 0 rem(12);
  .role{
    width: rem(56);
    height: rem(56);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    img{
      width: rem(56);
      height: rem(56);
    }
    span{
      font-size: rem(11);
      line-height: rem(16);
      color: #FFFFFF;
      opacity: 0.5;
    }
    &.active{
      span{
        opacity: 1;
      }
    }
  }
}
.top-btn{
  position: absolute;
  top: rem(-100);
  right: 0;
  padding: rem(4);
  font-size: 0;
  opacity: 0;
  transition: all 0.4s ease;
  img{
    width: rem(32);
    height: rem(32);
  }
  &.show{
    opacity: 1;
    transition: all 0.4s ease;
  }
}

</style>