<template>
  <div class="guangye-page">
    <div v-if="!selectedType" 
      class="index" :class="{ hide: clickType || selectedType, show: isIndexFadingIn }">
      <div class="loading-bg content100">
        <div class="loading-top"></div>
        <div class="loading-center">
          <div class="left"></div>
          <div class="right"></div>
        </div>
        <div class="loading-bottom"></div>
      </div>
      <!-- <div class="loading content100">
        <div class="space-t"></div>
        <div class="space-c" :class="{ play: aniPlay }" ref="loadingSpace">
          <div class="logo" ref="loadingLogo" :style="{ 
              height: aniPlay ? (loadingLogo.h + 'px') : '',  
              top: aniPlay ? (loadingLogo.t + 'px') : '', 
            }"></div>
          <div class="loading-text" :style="{ 
              width: loadingTxt.w + 'px', 
              height: loadingTxt.h + 'px',  
              top: loadingTxt.t + 'px',  
              left: '50%',
            }"></div>
          <div class="btn-con">
            <div class="btn" :class="{ active: clickType=='banshu' }" @click="handlerClickBtn('banshu')">
              <img class="img" src="@/assets/guangye/btn-banshu.png" />
              <img class="img-active" src="@/assets/guangye/btn-banshu-active.png" />
            </div>
            <div class="btn" :class="{ active: clickType=='cake' }" @click="handlerClickBtn('cake')">
              <img class="img" src="@/assets/guangye/btn-cake.png" />
              <img class="img-active" src="@/assets/guangye/btn-cake-active.png" />
            </div>
          </div>
        </div>
        <div class="space-b"></div>
      </div> -->
      <div class="loading2 content100 loading3" :class="{ hide: loadingFading }">
        <div style="flex:6;"></div>
        <img class="logo2" src="@/assets/guangye/logo2.png" />
        <img class="logo-s" src="@/assets/guangye/logo-s.png" />
        <div style="flex:7;"></div>
      </div>
      <div class="loading2 content100 loading4" :class="{ show: loadingFading }">
        <div style="flex:160;"></div>
        <img class="logo2" src="@/assets/guangye/logo2.png" />
        <div style="flex:68;"></div>
        <img class="logo-txt" src="@/assets/guangye/loading-txt2.png" />
        <div style="flex:78;"></div>
        <div class="btn-con">
          <div class="btn" :class="{ active: clickType=='banshu' }" @click="handlerClickBtn('banshu')">
            <img class="img" src="@/assets/guangye/btn-banshu.png" />
            <img class="img-active" src="@/assets/guangye/btn-banshu-active.png" />
          </div>
          <div class="btn" :class="{ active: clickType=='cake' }" @click="handlerClickBtn('cake')">
            <img class="img" src="@/assets/guangye/btn-cake.png" />
            <img class="img-active" src="@/assets/guangye/btn-cake-active.png" />
          </div>
        </div>
        <div style="flex:82;"></div>
        <img class="logo-s" src="@/assets/guangye/logo-s.png" />
        <div style="flex:78;"></div>
      </div>
    </div>
    <goods v-if="selectedType" 
      class="detail" :class="{ show: isDetailFadingIn }"
      :type="selectedType"
      @back="handlerClose"
      ></goods>
  </div>
</template>

<script>
// import wx from 'weixin-js-sdk'
import ajax from '@/utils/ajax.js'
import config from '@/utils/config'
import goods from './components/goods'

export default {
  components: {
    goods
  },
  data() {
    return {
      config,
      id: '',

      data: {},
      imgList: [],

      loadingLogo: {
        h: 0,
        t: 0,
      },
      loadingTxt: {
        w: 0,
        h: 0,
        t: 0,
      },
      aniPlay: false,

      loadingFading: false,

      // 详情
      clickType: "",
      selectedType: "", // banshu cake
      isDetailFadingIn: false,
      isIndexFadingIn: false,
    }
  },
  created() {
    // localStorage.setItem('backpagefullpath', encodeURIComponent(this.$route.fullPath))
  },
  mounted() {
    // this.startAni()

    this.$nextTick(()=>{
      setTimeout(()=>{
        this.loadingFading = true
      }, 800)
    })
  },
  destroyed() {

  },
  methods: {

    startAni(){
      this.$nextTick(() => {
        const width = this.$refs.loadingSpace.offsetWidth;
        const height = this.$refs.loadingSpace.offsetHeight;
        console.log(`宽度: ${width}, 高度: ${height}`);

        // const logo_w = this.$refs.loadingLogo.offsetWidth
        const logo_h = this.$refs.loadingLogo.offsetHeight

        const rate = 554 / (554+294)

        const s1_r = 82 / 1454
        const s2_r = 88 / 1454

        const s3 = Math.ceil(width * 44 / 307)
        const s1 = Math.round(Math.max(height*s1_r, 40))
        const s2 = Math.round(Math.max(height*s2_r, 48))
        const h1 = Math.min(logo_h, Math.round((height-s1-s2-s3)*rate))
        const h2 = Math.round((height-s1-s2-s3)*(1-rate))
        const w2 = Math.ceil(h2/296*368)

        const t1 = h1/2
        const t2 = h1+s1+h2/2

        console.log("s1, s2, s3", s1, s2, s3)

        // const scale1 = Math.min(h1/logo_h, 1)

        this.loadingTxt = {
          w: w2,
          h: h2,
          t: t2,
        }
        this.loadingLogo = {
          // s: scale1,
          h: h1,
          t: t1,
        }

        setTimeout(()=>{
          this.aniPlay = true
        }, 800)
      })
    },

    async getDetail() {
      const {data, code} = await ajax.post('/EventAd/detail', {
        id: this.id
      })

      if(code == 0) {
        if(data.title){
          document.title = data.title
        }

        this.data = data
        try{
          const content = JSON.parse(data.content)
          if(content && content.img_list){
            this.imgList = content.img_list
          }
        }catch(e){
          console.log("json解析失败", e)
        }
      }
    },

    handlerClickBtn(type){
      this.clickType = type
      this.isIndexFadingIn = false
      setTimeout(()=>{
        this.clickType = ""
        this.selectedType = type
      }, 300)
      setTimeout(()=>{
        this.isDetailFadingIn = true
      }, 400)
    },

    handlerClose(){
      this.clickType = ""
      this.isDetailFadingIn = false
      setTimeout(()=>{
        this.selectedType = ""
      }, 300)
      setTimeout(()=>{
        this.isIndexFadingIn = true
      }, 400)
    },
  }
}
</script>

<style lang="scss">
.guangye-page {
  position: relative;
  width: 100vw;
  height: 100vh;
  background: url('../../assets/guangye/bg.png') no-repeat;
  background-size: cover;
  background-position: top center;
}
.content100{
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
.loading-bg{
  display: flex;
  flex-direction: column;
  align-items: center;
  .loading-top,
  .loading-bottom{
    flex: 1;
    width: rem(354);
    //margin: 0 auto;
  }
  .loading-top{
    background: url('../../assets/guangye/bg-top.png') no-repeat;
    background-position: top;
    background-size: 100% auto;
  }
  .loading-bottom{
    background: url('../../assets/guangye/bg-bottom.png') no-repeat;
    background-position: bottom;
    background-size: 100% auto;
  }
  .loading-center{
    width: rem(364);
    height: rem(130);
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left,
    .right{
      width: rem(17);
      height: rem(70);
    }
    .left{
      background: url('../../assets/guangye/bg-left.png') no-repeat;
      background-size: contain;
    }
    .right{
      background: url('../../assets/guangye/bg-right.png') no-repeat;
      background-size: contain;
    }
  }
}
.loading{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // padding: 28vw 0 21vw;
  // padding: 14% 0 9%;
  .space-t{
    width: 100%;
    height: 12.37vh;
    min-height: rem(100);
  }
  .space-b{
    width: 100%;
    height: 9.76vh;
    min-height: rem(60);
  }
  .space-c{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: rem(307);
    position: relative;
    .logo{
      // flex: 1;
      width: rem(245);
      height: rem(326);
      background: url('../../assets/guangye/loading-logo.png') no-repeat;
      background-size: contain;
      background-position: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      transition: all 0.6s ease-in-out;
    }
    // .loading2{
    //   display: flex;
    //   flex-direction: column;
    //   justify-content: center;
    //   align-items: center;
    // }
    .loading-text{
      position: absolute;
      opacity: 0;
      width: rem(184);
      height: rem(148);
      background: url('../../assets/guangye/loading-text.png') no-repeat;
      background-size: contain;
      background-position: center;
      transform: translate(-50%,-50%);
      transition: all 0.4s ease-in-out 0.6s;
    }
    &.play{
      .loading-text{
        opacity: 1;
      }
      .btn-con{
        opacity: 1;
      }
    }
  }
  .btn-con{
    position: absolute;
    opacity: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    //width: rem(307);
    height: rem(56);
    display: flex;
    justify-content: space-between;
    transition: all 0.4s ease-in-out 0.7s;
    .btn{
      width: rem(146);
      height: rem(56);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      .img,
      .img-active{
        width: 100%;
        height: 100%;
      }
      .img-active{
        display: none;
      }
      &.active,
      &:active{
        .img{
          display: none;
        }
        .img-active{
          display: block;
        }
      }
    }
  }
}


.loading2{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .logo2{
    width: rem(227);
    height: rem(288);
  }
  .logo-s{
    width: rem(143);
    height: rem(29);
  }
  .logo2+.logo-s{
    margin-top: rem(27);
  }
  .logo-txt{
    width: rem(185);
    height: rem(128);
  }
  .btn-con{
    // position: absolute;
    // opacity: 0;
    // bottom: 0;
    // left: 0;
    // width: 100%;
    width: rem(307);
    height: rem(56);
    display: flex;
    justify-content: space-between;
    transition: all 0.4s ease-in-out 0.7s;
    .btn{
      width: rem(146);
      height: rem(56);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      .img,
      .img-active{
        width: 100%;
        height: 100%;
      }
      .img-active{
        display: none;
      }
      &.active,
      &:active{
        .img{
          display: none;
        }
        .img-active{
          display: block;
        }
      }
    }
  }
}

.loading3.hide{
  opacity: 0;
  transition: all 0.5s ease;
}
.loading4{
  opacity: 0;
}
.loading4.show{
  opacity: 1;
  transition: all 0.5s linear 0.2s;
}


// 
.index{
  transition: all 0.3s ease-in-out;
  &.hide{
    opacity: 0;
    transition: all 0.3s ease-in-out;
  }
  &.show{
    opacity: 1;
    transition: all 0.3s ease-in-out;
  }
}
.detail{
  opacity: 0;
  transition: all 0.3s ease-in-out;
  &.show{
    opacity: 1;
    transition: all 0.3s ease-in-out;
  }
}

</style>