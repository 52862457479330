import CONFIG from '@/utils/config'

const goods_haxe_test = {
  "cake-1": {
    // type_id: 58,
    goods_id: 67,
  },
  "cake-2": {
    // type_id: 58,
    goods_id: 68,
  },
  "cake-3": {
    // type_id: 58,
    goods_id: 69,
  },
  "cake-4": {
    // type_id: 58,
    goods_id: 70,
  },
  "cake-5": {
    // type_id: 58,
    goods_id: 71,
  },
  "banshu-1": {
    // type_id: 58,
    goods_id: 57,
  },
  "banshu-2": {
    // type_id: 58,
    goods_id: 58,
  },
  "banshu-3": {
    // type_id: 58,
    goods_id: 59,
  },
  "banshu-4": {
    // type_id: 58,
    goods_id: 60,
  },
  "banshu-5": {
    // type_id: 58,
    goods_id: 61,
  },
  "m-banshu-1": {
    goods_id: 62,
  },
  "m-banshu-2": {
    goods_id: 63,
  },
  "m-banshu-3": {
    goods_id: 64,
  },
  "m-banshu-4": {
    goods_id: 65,
  },
  "m-banshu-5": {
    goods_id: 66,
  },
}


const goods_haxe_online = {
  "cake-1": {
    goods_id: 734,
  },
  "cake-2": {
    goods_id: 735,
  },
  "cake-3": {
    goods_id: 736,
  },
  "cake-4": {
    goods_id: 737,
  },
  "cake-5": {
    goods_id: 738,
  },
  // 早鸟
  // "cake-1": {
  //   goods_id: 723,
  // },
  // "cake-2": {
  //   goods_id: 722,
  // },
  // "cake-3": {
  //   goods_id: 721,
  // },
  // "cake-4": {
  //   goods_id: 720,
  // },
  // "cake-5": {
  //   goods_id: 719,
  // },
  "banshu-1": {
    goods_id: 733,
  },
  "banshu-2": {
    goods_id: 732,
  },
  "banshu-3": {
    goods_id: 731,
  },
  "banshu-4": {
    goods_id: 730,
  },
  "banshu-5": {
    goods_id: 729,
  },
  "m-banshu-1": {
    goods_id: 728,
  },
  "m-banshu-2": {
    goods_id: 727,
  },
  "m-banshu-3": {
    goods_id: 726,
  },
  "m-banshu-4": {
    goods_id: 725,
  },
  "m-banshu-5": {
    goods_id: 724,
  },
}


export const goodsHaxe = CONFIG.IS_TEST ? goods_haxe_test : goods_haxe_online